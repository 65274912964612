<template>
  <esmp-select
    disabled
    :placeholder="placeholder"
    :value="''"
  >
    <esmp-select-option
      v-for="item in values"
      :key="item.techId"
      :value="item.techId"
    >
      {{ item.name }}
    </esmp-select-option>
  </esmp-select>
</template>

<script>
export default {
  name: 'SelectView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    placeholder() {
      return this.settings.find((el) => el.techName === 'name').value;
    },
    values() {
      return this.settings.find((el) => el.techName === 'values').values || [];
    },
  },
};
</script>
